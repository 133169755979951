import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Parallax, Background } from "react-parallax";

import { Layout, PostCard, Pagination } from "../components/common";
import { MetaData } from "../components/common/meta";
import { getNPTH, getMiscTag, getOrder } from "../utils/index";

const parseFullName = require("parse-full-name").parseFullName;

const sortEnum = {
    author: "author",
    theme: "theme",
    genre: "genre",
};

const viewEnum = {
    grid: "grid",
    list: "list",
};

const SearchBar = ({ search, sort, setSearch, setSort, view, setView }) => {
    return (
        <div className="bg-white flex flex-row h-24 w-full">
            <div className="flex flex-col items-center justify-center ml-4">
                <svg
                    class="w-10 h-10"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                </svg>
            </div>
            <div className="flex flex-row">
                <div
                    className="ml-4 flex items-center"
                    style={{ height: "100%" }}
                >
                    <input
                        style={{ height: "90%" }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        style={{
                            borderBottom: "2px solid rgb(153, 153,153)",
                            color: "rgb(60, 72, 78)",
                            fontSize: 24,
                            width: "32rem",
                        }}
                    />
                </div>
                <div className="flex flex-row filters">
                    <div
                        className={`ml-16 mr-6 flex justify-center items-center tracking-widest`}
                        style={{ fontSize: 24, height: "100%" }}
                    >
                        SORT:
                    </div>
                    <div
                        className={`px-8 cursor-pointer flex justify-center items-center tracking-widest hover:bg-gray-button hover:text-white transition ease-in-out duration-150u ${
                            sort.param === sortEnum.author
                                ? "selected-filter"
                                : "unselected-filter"
                        }`}
                        onClick={() =>
                            setSort({
                                click:
                                    sort.param === sortEnum.author
                                        ? sort.click + 1
                                        : 0,
                                param: sortEnum.author,
                            })
                        }
                        style={{ fontSize: 24, height: "100%" }}
                    >
                        AUTHOR
                    </div>
                    <div
                        className={`px-8 cursor-pointer flex justify-center items-center tracking-widest hover:bg-gray-button hover:text-white transition ease-in-out duration-150 ${
                            sort.param === sortEnum.theme
                                ? "selected-filter"
                                : "unselected-filter"
                        }`}
                        onClick={() =>
                            setSort({
                                click:
                                    sort.param === sortEnum.theme
                                        ? sort.click + 1
                                        : 0,
                                param: sortEnum.theme,
                            })
                        }
                        style={{ fontSize: 24, height: "100%" }}
                    >
                        THEME
                    </div>
                    <div
                        className={`px-8 cursor-pointer flex justify-center items-center tracking-widest hover:bg-gray-button hover:text-white transition ease-in-out duration-150 ${
                            sort.param === sortEnum.genre
                                ? "selected-filter"
                                : "unselected-filter"
                        }`}
                        onClick={() =>
                            setSort({
                                click:
                                    sort.param === sortEnum.genre
                                        ? sort.click + 1
                                        : 0,
                                param: sortEnum.genre,
                            })
                        }
                        style={{ fontSize: 24, height: "100%" }}
                    >
                        GENRE
                    </div>
                </div>
            </div>
            <div
                className="flex flex-row items-center justify-end"
                style={{ width: "100%" }}
            >
                <div
                    className={`p-4 flex items-center justify-center cursor-pointer ${
                        view === viewEnum.grid
                            ? "selected-filter"
                            : "unselected-filter"
                    }`}
                    onClick={() => setView(viewEnum.grid)}
                    style={{ height: "100%" }}
                >
                    <svg
                        class="w-10 h-10"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </div>
                <div
                    className={`p-4 flex items-center justify-center cursor-pointer ${
                        view === viewEnum.list
                            ? "selected-filter"
                            : "unselected-filter"
                    }`}
                    onClick={() => setView(viewEnum.list)}
                    style={{ height: "100%" }}
                >
                    <svg
                        className="block h-10 w-10"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */
const Tag = ({ data, location, pageContext }) => {
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState({ param: null, click: 0 });
    // const [click, setClick] = useState({theme: 0, author: 0});
    const [posts, setPosts] = useState(data.allGhostPost.edges);
    const [view, setView] = useState(viewEnum.grid);
    const tag = data.ghostTag;

    const sortPosts = (param, ascending, cb) => {
        const p = [...posts];
        p.sort(function (a, b) {
            if (param === sortEnum.author) {
                const nameA = parseFullName(
                    a.node.primary_author.name
                ).last.toUpperCase();
                const nameB = parseFullName(
                    b.node.primary_author.name
                ).last.toUpperCase();
                return ascending
                    ? nameA.localeCompare(nameB)
                    : nameB.localeCompare(nameA);
            } else if (param === sortEnum.theme) {
                const tA = getNPTH(a.node) || { name: "" };
                const tB = getNPTH(b.node) || { name: "" };
                return ascending
                    ? tA.name.localeCompare(tB.name)
                    : tB.name.localeCompare(tA.name);
            } else if (param === sortEnum.genre) {
                const tA = getMiscTag(a.node) || { name: "" };
                const tB = getMiscTag(b.node) || { name: "" };
                return ascending
                    ? tA.name.localeCompare(tB.name)
                    : tB.name.localeCompare(tA.name);
            } else {
                let oA = getOrder(a.node);
                let oB = getOrder(b.node);
                if (typeof oA !== "number") oA = Infinity;
                if (typeof oB !== "number") oB = Infinity;
                return oA - oB;
            }
        });
        setPosts(p);
        cb && cb();
    };
    useEffect(() => {
        sortPosts(sort.param, sort.click % 2 === 0);
        // setClick()
    }, [sort]);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (
                    ref.current &&
                    !ref.current.contains(event.target) &&
                    !event.target.classList.contains("post-card") &&
                    !(
                        event.target.parentElement &&
                        event.target.parentElement.classList.contains(
                            "post-card"
                        )
                    )
                ) {
                    sortPosts(null, true, () =>
                        setSort({ ...sort, param: null })
                    );
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <>
            <MetaData data={data} location={location} type="series" />
            <div className="h-24"></div>
            <Layout isIssue>
                <Parallax strength={200} blur={5}>
                    <Background
                        className="custom-bg"
                        bgStyle={{ left: 0, position: "relative" }}
                    >
                        <div
                            style={{
                                backgroundImage: `url('${tag.feature_image}')`,
                                height: "1000vh",
                                width: 5000,
                                backgroundRepeat: "repeat",
                            }}
                        />
                    </Background>
                    {/* <div className="mt-24 absolute" style={{backgroundImage: `url('${tag.feature_image}')`, top: 0, left: 0, width: '100vw', height: '100%', position: 'fixed'}} /> */}
                    <div
                        className="container-issue"
                        style={{ position: "relative" }}
                    >
                        <header
                            className="bg-white py-24"
                            style={{ opacity: 0.9 }}
                        >
                            <div className="hidden sm:flex flex-col items-center justify-center">
                                <h1 className="font-serif text-6xl mb-0">
                                    {tag.name}
                                </h1>
                                {/* {tag.description ? <p className="font-serif text-6xl mb-8">{tag.description}</p> : null } */}
                            </div>
                            <div className="sm:hidden flex-col items-center justify-center text-center">
                                <h1 className="font-serif text-6xl mb-0">
                                    {tag.name.split("-")[0]}
                                </h1>
                                {tag.name.split("-")[1] && (
                                    <h1 className="font-serif text-6xl mb-0">
                                        {tag.name.split("-")[1]}
                                    </h1>
                                )}
                                {/* {tag.description ? <p className="font-serif text-6xl mb-8">{tag.description}</p> : null } */}
                            </div>
                        </header>
                        <div
                            ref={wrapperRef}
                            className="hidden sm:flex sm:w-full"
                        >
                            <SearchBar
                                search={search}
                                setSearch={setSearch}
                                sort={sort}
                                setSort={setSort}
                                view={view}
                                setView={setView}
                            />
                        </div>
                        <section
                            className={`${
                                view === viewEnum.grid
                                    ? "post-feed"
                                    : "post-list"
                            } mt-8 mb-20`}
                        >
                            {posts.map(({ node }) => {
                                const secondary_tags = node.tags.slice(1);
                                const secondary_tag_text = secondary_tags.length
                                    ? secondary_tags
                                          .map((t) => t.name)
                                          .join(" ")
                                    : "";
                                if (search) {
                                    const l_search = search.toLocaleLowerCase();
                                    const l_name = node.primary_author.name.toLocaleLowerCase();
                                    const l_title = node.title.toLocaleLowerCase();
                                    const l_text = node.plaintext.toLocaleLowerCase();
                                    const l_tag = secondary_tag_text.toLocaleLowerCase();
                                    if (
                                        l_name.includes(l_search) ||
                                        l_title.includes(l_search) ||
                                        l_text.includes(l_search) ||
                                        l_tag.includes(l_search)
                                    ) {
                                        return (
                                            <PostCard
                                                key={node.id}
                                                post={node}
                                            />
                                        );
                                    }
                                } else {
                                    return (
                                        <PostCard key={node.id} post={node} />
                                    );
                                }
                            })}
                        </section>
                        {/* <Pagination pageContext={pageContext} /> */}
                    </div>
                </Parallax>
            </Layout>
        </>
    );
};

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Tag;

export const pageQuery = graphql`
    query GhostIssueQuery($slug: String!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
